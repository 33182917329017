export const LEAD_ASSIGN_USER = "LEAD_ASSIGN_USER"
export const LEAD_ASSIGN_USER_SUCCESS = "LEAD_ASSIGN_USER_SUCCESS"
export const LEAD_ASSIGN_USER_FAILED = "LEAD_ASSIGN_USER_FAILED"

export const LEAD_ASSIGN_EXPERT = "LEAD_ASSIGN_EXPERT"
export const LEAD_ASSIGN_EXPERT_SUCCESS = "LEAD_ASSIGN_EXPERT_SUCCESS"
export const LEAD_ASSIGN_EXPERT_FAILED = "LEAD_ASSIGN_EXPERT_FAILED"

export const LEAD_INSURANCE_COMPANY = "LEAD_INSURANCE_COMPANY"
export const LEAD_INSURANCE_COMPANY_SUCCESS = "LEAD_INSURANCE_COMPANY_SUCCESS"
export const LEAD_INSURANCE_COMPANY_FAILED = "LEAD_INSURANCE_COMPANY_FAILED"

export const LEAD_DATA_WITH_STATUS = "LEAD_DATA_WITH_STATUS"
export const LEAD_DATA_WITH_STATUS_SUCCESS = "LEAD_DATA_WITH_STATUS_SUCCESS"
export const LEAD_DATA_WITH_STATUS_FAILED = "LEAD_DATA_WITH_STATUS_FAILED"

export const LEAD_USERS = "LEAD_USERS"
export const LEAD_USERS_SUCCESS = "LEAD_USERS_SUCCESS"
export const LEAD_USERS_FAILED = "LEAD_USERS_FAILED"

export const SEARCH_BY_MAIL_AND_PHONE = "SEARCH_BY_MAIL_AND_PHONE"
export const SEARCH_BY_MAIL_AND_PHONE_SUCCESS = "SEARCH_BY_MAIL_AND_PHONE_SUCCESS"
export const SEARCH_BY_MAIL_AND_PHONE_FAILED = "SEARCH_BY_MAIL_AND_PHONE_FAILED"

export const LEAD_DOWNLOAD_REPORT = "LEAD_DOWNLOAD_REPORT"
export const LEAD_DOWNLOAD_REPORT_SUCCESS = "LEAD_DOWNLOAD_REPORT_SUCCESS"
export const LEAD_DOWNLOAD_REPORT_FAILED = "LEAD_DOWNLOAD_REPORT_FAILED"

export const LEAD_GET_MISSELLING = "LEAD_GET_MISSELLING"
export const LEAD_GET_MISSELLING_SUCCESS = "LEAD_GET_MISSELLING_SUCCESS"
export const LEAD_GET_MISSELLING_FAILED = "LEAD_GET_MISSELLING_FAILED"

export const LEAD_ASSIGN_USER_SAVE = "LEAD_ASSIGN_USER_SAVE"
export const LEAD_ASSIGN_USER_SAVE_SUCCESS = "LEAD_ASSIGN_USER_SAVE_SUCCESS"
export const LEAD_ASSIGN_USER_SAVE_FAILED = "LEAD_ASSIGN_USER_SAVE_FAILED"

export const LEAD_ASSIGN_EXPERT_SAVE = "LEAD_ASSIGN_EXPERT_SAVE"
export const LEAD_ASSIGN_EXPERT_SAVE_SUCCESS = "LEAD_ASSIGN_EXPERT_SAVE_SUCCESS"
export const LEAD_ASSIGN_EXPERT_SAVE_FAILED = "LEAD_ASSIGN_EXPERT_SAVE_FAILED"

export const LEAD_ACCEPT_LEAD = "LEAD_ACCEPT_LEAD"
export const LEAD_ACCEPT_LEAD_SUCCESS = "LEAD_ACCEPT_LEAD_SUCCESS"
export const LEAD_ACCEPT_LEAD_FAILED = "LEAD_ACCEPT_LEAD_FAILED"


export const LEAD_REJECT_LEAD = "LEAD_REJECT_LEAD"
export const LEAD_REJECT_LEAD_SUCCESSS = "LEAD_REJECT_LEAD_SUCCESSS"
export const LEAD_REJECT_LEAD_FAILED = "LEAD_REJECT_LEAD_FAILED"

export const LEAD_COMM_HISTORY_UPDATE_COMMENT = "LEAD_COMM_HISTORY_UPDATE_COMMENT"
export const LEAD_COMM_HISTORY_UPDATE_COMMENT_SUCCESS = "LEAD_COMM_HISTORY_UPDATE_COMMENT_SUCCESS"
export const LEAD_COMM_HISTORY_UPDATE_COMMENT_FAILED = "LEAD_COMM_HISTORY_UPDATE_COMMENT_FAILED"

export const LEAD_COMM_HISTORY_ADD_COMMENT = "LEAD_COMM_HISTORY_ADD_COMMENT"
export const LEAD_COMM_HISTORY_ADD_COMMENT_SUCCESS = "LEAD_COMM_HISTORY_ADD_COMMENT_SUCCESS"
export const LEAD_COMM_HISTORY_ADD_COMMENT_FAILED = "LEAD_COMM_HISTORY_ADD_COMMENT_FAILED"

export const LEAD_COM_FOR_PARTNER = "LEAD_COM_FOR_PARTNER"
export const LEAD_COM_FOR_PARTNER_SUCCESS = "LEAD_COM_FOR_PARTNER_SUCCESS"
export const LEAD_COM_FOR_PARTNER_FAILED = "LEAD_COM_FOR_PARTNER_FAILED"

export const LEAD_FETCH_BY_ID = "LEAD_FETCH_BY_ID"
export const LEAD_FETCH_BY_ID_SUCCESS = "LEAD_FETCH_BY_ID_SUCCESS"
export const LEAD_FETCH_BY_ID_FAILED = "LEAD_FETCH_BY_ID_FAILED"

export const LEAD_GET_SINGLE_DATA_SUCCESS = "LEAD_GET_SINGLE_DATA_SUCCESS"
export const LEAD_GET_SINGLE_DATA_FAILED = "LEAD_GET_SINGLE_DATA_FAILED"

export const LEAD_FILTRATION_DATA_SUCCESS = "LEAD_FILTRATION_DATA_SUCCESS"
export const LEAD_FILTRATION_DATA_FAILED = "LEAD_FILTRATION_DATA_FAILED"

export const LEAD_INS_COMPANY_LIST_SUCCESS = "LEAD_INS_COMPANY_LIST_SUCCESS"
export const LEAD_INS_COMPANY_LIST_FAILED = "LEAD_INS_COMPANY_LIST_FAILED"

export const LEAD_GET_USERDETAILS_SUCCESS = "LEAD_GET_USERDETAILS_SUCCESS"
export const LEAD_GET_USERDETAILS_FAILED = "LEAD_GET_USERDETAILS_FAILED"

export const LEAD_HI_FILTRATION_SUCCESS = "LEAD_HI_FILTRATION_SUCCESS"
export const LEAD_HI_FILTRATION_FAILED = "LEAD_HI_FILTRATION_FAILED"

export const LEAD_HI_SINGLE_FILTER_SUCCESS = "LEAD_HI_SINGLE_FILTER_SUCCESS"
export const LEAD_HI_SINGLE_FILTER_FAILED = "LEAD_HI_SINGLE_FILTER_FAILED"

export const LEAD_SAVE_DETAILS_SUCCESS = "LEAD_SAVE_DETAILS_SUCCESS"
export const LEAD_SAVE_DETAILS_FAILED = "LEAD_SAVE_DETAILS_FAILED"

export const LEAD_SAVE_POL_HOLDER_DETAILS_SUCCESS = "LEAD_SAVE_POL_HOLDER_DETAILS_SUCCESS"
export const LEAD_SAVE_POL_HOLDER_DETAILS_FAILED = "LEAD_SAVE_POL_HOLDER_DETAILS_FAILED"

export const LEAD_SAVE_COMPLAINT_DETAILS_SUCCESS = "LEAD_SAVE_COMPLAINT_DETAILS_SUCCESS"
export const LEAD_SAVE_COMPLAINT_DETAILS_FAILED = "LEAD_SAVE_COMPLAINT_DETAILS_FAILED"

export const LEAD_UPDATE_FILT_PASSWORD_SUCCESS = "LEAD_UPDATE_FILT_PASSWORD_SUCCESS"
export const LEAD_UPDATE_FILT_PASSWORD_FAILED = "LEAD_UPDATE_FILT_PASSWORD_FAILED"

export const LEAD_ADD_COMMUNICATION_SUCCESS = "LEAD_ADD_COMMUNICATION_SUCCESS"
export const LEAD_ADD_COMMUNICATION_FAILED = "LEAD_ADD_COMMUNICATION_FAILED"

export const LEAD_SUBMIT_HEALTH_SUCCESS = "LEAD_SUBMIT_HEALTH_SUCCESS"
export const LEAD_SUBMIT_HEALTH_FAILED = "LEAD_SUBMIT_HEALTH_FAILED"

export const LEAD_SAVE_VICTIM_DETAILS_SUCCESS = "LEAD_SAVE_VICTIM_DETAILS_SUCCESS"
export const LEAD_SAVE_VICTIM_DETAILS_FAILED = "LEAD_SAVE_VICTIM_DETAILS_FAILED"

export const LEAD_SAVE_FRAUD_DETAILS_SUCCESS = "LEAD_SAVE_FRAUD_DETAILS_SUCCESS"
export const LEAD_SAVE_FRAUD_DETAILS_FAILED = "LEAD_SAVE_FRAUD_DETAILS_FAILED"

export const LEAD_SAVE_PROOF_DETAILS_SUCCESS = "LEAD_SAVE_PROOF_DETAILS_SUCCESS"
export const LEAD_SAVE_PROOF_DETAILS_FAILED = "LEAD_SAVE_PROOF_DETAILS_FAILED"

export const LEAD_SUBMIT_MISSELL_SUCCESS = "LEAD_SUBMIT_MISSELL_SUCCESS"
export const LEAD_SUBMIT_MISSELL_FAILED = "LEAD_SUBMIT_MISSELL_FAILED"

export const LEAD_FLUSH_FILTRATION_SUCCESS = "LEAD_FLUSH_FILTRATION_SUCCESS"

export const LEAD_VICTIM_DETAILS_SUCCESS = "LEAD_VICTIM_DETAILS_SUCCESS"
export const LEAD_VICTIM_DETAILS_FAILED = "LEAD_VICTIM_DETAILS_FAILED"

export const CALL_LOGS_FOR_CUSTOMER = "CALL_LOGS_FOR_CUSTOMER"
export const CALL_LOGS_FOR_CUSTOMER_SUCCESS = "CALL_LOGS_FOR_CUSTOMER_SUCCESS"
export const CALL_LOGS_FOR_CUSTOMER_FAILED = "CALL_LOGS_FOR_CUSTOMER_FAILED"
export const CALL_LOGS_FOR_CUSTOMER_CLEAR_SUCCESS = "CALL_LOGS_FOR_CUSTOMER_CLEAR_SUCCESS"

export const LEAD_SEND_MESSAGE_TO_USER = "LEAD_SEND_MESSAGE_TO_USER"
export const LEAD_SEND_MESSAGE_TO_USER_SUCCESS = "LEAD_SEND_MESSAGE_TO_USER_SUCCESS"
export const LEAD_SEND_MESSAGE_TO_USER_FAILED = "LEAD_SEND_MESSAGE_TO_USER_FAILED"

export const LEAD_CANCEL_LEAD = "LEAD_CANCEL_LEAD"
export const LEAD_CANCEL_LEAD_SUCCESS = "LEAD_CANCEL_LEAD_SUCCESS"
export const LEAD_CANCEL_LEAD_FAILED = "LEAD_CANCEL_LEAD_FAILED"

export const LEAD_GET_POLICY_TYPE = "LEAD_GET_POLICY_TYPE"
export const LEAD_GET_POLICY_TYPE_SUCCESS = "LEAD_GET_POLICY_TYPE_SUCCESS"
export const LEAD_GET_POLICY_TYPE_FAILED = "LEAD_GET_POLICY_TYPE_FAILED"


export const LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE = "LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE"
export const LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE_SUCCESS = "LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE_SUCCESS"
export const LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE_FAILED = "LEAD_GET_POLICY_TYPE_COMPLAINT_TYPE_FAILED"

export const Lead_UPDATE_LEAD = "Lead_UPDATE_LEAD"
export const Lead_UPDATE_LEAD_SUCCESS = "Lead_UPDATE_LEAD_SUCCESS"
export const Lead_UPDATE_LEAD_FAILED = "Lead_UPDATE_LEAD_FAILED"

export const ADD_COMPANY_NOTICE_REPLY = "ADD_COMPANY_NOTICE_REPLY"
export const FAILED_COMPANY_NOTICE_REPLY = "FAILED_COMPANY_NOTICE_REPLY"

export const LEAD_ADD_FOLLOW_UP = "LEAD_ADD_FOLLOW_UP"
export const LEAD_ADD_FOLLOW_UP_SUCCESS = "LEAD_ADD_FOLLOW_UP_SUCCESS"
export const LEAD_ADD_FOLLOW_UP_FAILED = "LEAD_ADD_FOLLOW_UP_FAILED"

export const LEAD_UPLOAD_DOCUMENT_FORM = "LEAD_UPLOAD_DOCUMENT_FORM"
export const LEAD_UPLOAD_DOCUMENT_FORM_FAILED = "LEAD_UPLOAD_DOCUMENT_FORM_FAILED"
export const LEAD_UPLOAD_DOCUMENT_FORM_SUCCESS = "LEAD_UPLOAD_DOCUMENT_FORM_SUCCESS"

export const LEAD_DOC_UPLOAD_FORM_CHANGE_SUCCESS = "LEAD_DOC_UPLOAD_FORM_CHANGE_SUCCESS"
export const LEAD_DOC_SET_LOADER_SUCCESS = "LEAD_DOC_SET_LOADER_SUCCESS"

export const LEAD_DOC_UPLOAD_FORM_SUCCESS = "LEAD_DOC_UPLOAD_FORM_SUCCESS"
export const LEAD_DOC_UPLOAD_FORM_FAILED = "LEAD_DOC_UPLOAD_FORM_FAILED"
export const LEAD_INTERNAL_DOC_UPLOAD_FORM_SUCCESS = "LEAD_INTERNAL_DOC_UPLOAD_FORM_SUCCESS"
export const LEAD_INTERNAL_DOC_UPLOAD_FORM_FAILED = "LEAD_INTERNAL_DOC_UPLOAD_FORM_FAILED"
export const LEAD_DOC_UPLOAD_FORM_COMBINED_SUCCESS = "LEAD_DOC_UPLOAD_FORM_COMBINED_SUCCESS"
export const LEAD_DOC_UPLOAD_FORM_COMBINED_FAILED = "LEAD_DOC_UPLOAD_FORM_COMBINED_FAILED"

export const LEAD_DOC_UPLOAD_SUCCESS = "LEAD_DOC_UPLOAD_SUCCESS"
export const LEAD_DOC_UPLOAD_FAILED = "LEAD_DOC_UPLOAD_FAILED"

export const LEAD_DOC_ZIP_SUCCESS = "LEAD_DOC_ZIP_SUCCESS"
export const LEAD_DOC_ZIP_FAILED = "LEAD_DOC_ZIP_FAILED"

export const LEAD_DOC_PASSWORD_SUCCESS = "LEAD_DOC_PASSWORD_SUCCESS"
export const LEAD_DOC_PASSWORD_FAILED = "LEAD_DOC_PASSWORD_FAILED"

export const LEAD_GET_INSURANCE_COMPANIES = "LEAD_GET_INSURANCE_COMPANIES"
export const LEAD_GET_INSURANCE_COMPANIES_SUCCESS = "LEAD_GET_INSURANCE_COMPANIES_SUCCESS"
export const LEAD_GET_INSURANCE_COMPANIES_FAILED = "LEAD_GET_INSURANCE_COMPANIES_FAILED"

export const LEAD_ADD_IVR_LEAD = "LEAD_ADD_IVR_LEAD"
export const LEAD_ADD_IVR_LEAD_SUCCESS = "LEAD_ADD_IVR_LEAD_SUCCESS"
export const LEAD_ADD_IVR_LEAD_FAILED = "LEAD_ADD_IVR_LEAD_FAILED"


export const LEAD_SEND_WHATSAPP_MESSAGE = "LEAD_SEND_WHATSAPP_MESSAGE"
export const LEAD_SEND_WHATSAPP_MESSAGE_SUCCESS = "LEAD_SEND_WHATSAPP_MESSAGE_SUCCESS"
export const LEAD_SEND_WHATSAPP_MESSAGE_FAILED = "LEAD_SEND_WHATSAPP_MESSAGE_FAILED"

export const LEAD_MAKE_CALL = "LEAD_MAKE_CALL"
export const LEAD_MAKE_SERVETEL_CALL = "LEAD_MAKE_SERVETEL_CALL"
export const LEAD_MAKE_CALL_SUCCESS = "LEAD_MAKE_CALL_SUCCESS"
export const LEAD_MAKE_CALL_FAILED = "LEAD_MAKE_CALL_FAILED"

export const LEAD_EXE_GET_SELECTED_DROPDOWN = "LEAD_EXE_GET_SELECTED_DROPDOWN"
export const LEAD_EXE_GET_SELECTED_DROPDOWN_SUCCESS = "LEAD_EXE_GET_SELECTED_DROPDOWN_SUCCESS"
export const LEAD_EXE_GET_SELECTED_DROPDOWN_FAILED = "LEAD_EXE_GET_SELECTED_DROPDOWN_FAILED"

export const LEAD_EXE_UPDATE = "LEAD_EXE_UPDATE"
export const LEAD_EXE_UPDATE_SUCCESS = "LEAD_EXE_UPDATE_SUCCESS"
export const LEAD_EXE_UPDATE_FAILED = "LEAD_EXE_UPDATE_FAILED"

export const LEAD_EXE_SAVE_ADD_DETAIL = "LEAD_EXE_SAVE_ADD_DETAIL"
export const LEAD_EXE_SAVE_ADD_DETAIL_SUCCESS = "LEAD_EXE_SAVE_ADD_DETAIL_SUCCESS"
export const LEAD_EXE_SAVE_ADD_DETAIL_FAILED = "LEAD_EXE_SAVE_ADD_DETAIL_FAILED"

export const LEAD_EXE_REQUEST_SERVICE_RATE = "LEAD_EXE_REQUEST_SERVICE_RATE"
export const LEAD_EXE_REQUEST_SERVICE_RATE_SUCCESS = "LEAD_EXE_REQUEST_SERVICE_RATE_SUCCESS"
export const LEAD_EXE_REQUEST_SERVICE_RATE_FAILED = "LEAD_EXE_REQUEST_SERVICE_RATE_FAILED"

export const LEAD_YELLOW_TRANSCRIPT_GENERATE = "LEAD_YELLOW_TRANSCRIPT_GENERATE"
export const LEAD_YELLOW_TRANSCRIPT_GENERATE_SUCCESS = "LEAD_YELLOW_TRANSCRIPT_GENERATE_SUCCESS"
export const LEAD_YELLOW_TRANSCRIPT_GENERATE_FAILED = "LEAD_YELLOW_TRANSCRIPT_GENERATE_FAILED"

export const LEAD_YELLOW_TRANSCRIPT_SEND = "LEAD_YELLOW_TRANSCRIPT_SEND"
export const LEAD_YELLOW_TRANSCRIPT_SEND_SUCCESS = "LEAD_YELLOW_TRANSCRIPT_SEND_SUCCESS"
export const LEAD_YELLOW_TRANSCRIPT_SEND_FAILED = "LEAD_YELLOW_TRANSCRIPT_SEND_FAILED"

export const LEAD_MOVE_TO_POLIFYX_PENDING = "LEAD_MOVE_TO_POLIFYX_PENDING"
export const LEAD_MOVE_TO_POLIFYX_PENDING_SUCCESS = "LEAD_MOVE_TO_POLIFYX_PENDING_SUCCESS"
export const LEAD_MOVE_TO_POLIFYX_PENDING_FAILED = "LEAD_MOVE_TO_POLIFYX_PENDING_FAILED"

export const LEAD_MOVE_TO_PENDING = "LEAD_MOVE_TO_PENDING"
export const LEAD_MOVE_TO_PENDING_SUCCESS = "LEAD_MOVE_TO_PENDING_SUCCESS"
export const LEAD_MOVE_TO_PENDING_FAILED = "LEAD_MOVE_TO_PENDING_FAILED"

export const LEAD_UPDATE_NR_DETAILS = "LEAD_UPDATE_NR_DETAILS"
export const LEAD_UPDATE_NR_DETAILS_SUCCESS = "LEAD_UPDATE_NR_DETAILS_SUCCESS"
export const LEAD_UPDATE_NR_DETAILS_FAILED = "LEAD_UPDATE_NR_DETAILS_FAILED"

export const LEAD_EXE_GET_HI_FILTERATION = "LEAD_EXE_GET_HI_FILTERATION"
export const LEAD_EXE_GET_HI_FILTERATION_SUCCESS = "LEAD_EXE_GET_HI_FILTERATION_SUCCESS"
export const LEAD_EXE_GET_HI_FILTERATION_FAILED = "LEAD_EXE_GET_HI_FILTERATION_FAILED"

export const EXE_INTERNAL_DOC_UPLOAD = "EXE_INTERNAL_DOC_UPLOAD"
export const EXE_INTERNAL_DOC_UPLOAD_SUCCESS = "EXE_INTERNAL_DOC_UPLOAD_SUCCESS"
export const EXE_INTERNAL_DOC_UPLOAD_FAILED = "EXE_INTERNAL_DOC_UPLOAD_FAILED"

export const EXE_STATUS_LEAD_COUNT = "EXE_STATUS_LEAD_COUNT"
export const EXE_STATUS_LEAD_COUNT_SUCCESS = "EXE_STATUS_LEAD_COUNT_SUCCESS"
export const EXE_STATUS_LEAD_COUNT_FAILED = "EXE_STATUS_LEAD_COUNT_FAILED"

export const LEAD_SET_SEARCH_VALUE = "LEAD_SET_SEARCH_VALUE"
export const LEAD_SET_SEARCH_VALUE_SUCCESS = "LEAD_SET_SEARCH_VALUE_SUCCESS"
export const LEAD_SET_SEARCH_VALUE_FAILED = "LEAD_SET_SEARCH_VALUE_FAILED"

export const LEAD_SET_CURRENT_STATUS = "LEAD_SET_CURRENT_STATUS"
export const LEAD_SET_CURRENT_STATUS_SUCCESS = "LEAD_SET_CURRENT_STATUS_SUCCESS"
export const LEAD_SET_CURRENT_STATUS_FAILED = "LEAD_SET_CURRENT_STATUS_FAILED"

export const LEAD_DOC_VERIFICATION_PENDING_OPTIONS = "LEAD_DOC_VERIFICATION_PENDING_OPTIONS"
export const LEAD_DOC_VERIFICATION_PENDING_OPTIONS_SUCCESS = "LEAD_DOC_VERIFICATION_PENDING_OPTIONS_SUCCESS"
export const LEAD_DOC_VERIFICATION_PENDING_OPTIONS_FAILED = "LEAD_DOC_VERIFICATION_PENDING_OPTIONS_FAILED"

export const LEAD_DOC_UPDATE_DOC_LIST = "LEAD_DOC_UPDATE_DOC_LIST"
export const LEAD_DOC_UPDATE_DOC_LIST_SUCCESS = "LEAD_DOC_UPDATE_DOC_LIST_SUCCESS"
export const LEAD_DOC_UPDATE_DOC_LIST_FAILED = "LEAD_DOC_UPDATE_DOC_LIST_FAILED"

export const LEAD_DELETE_DOCUMENT = "LEAD_DELETE_DOCUMENT"
export const LEAD_DELETE_DOCUMENT_SUCCESS = "LEAD_DELETE_DOCUMENT_SUCCESS"
export const LEAD_DELETE_DOCUMENT_FAILED = "LEAD_DELETE_DOCUMENT_FAILED"

export const LEAD_EXE_UPDATE_NR_SECOND = "LEAD_EXE_UPDATE_NR_SECOND"
export const LEAD_EXE_UPDATE_NR_SECOND_SUCCESS = "LEAD_EXE_UPDATE_NR_SECOND_SUCCESS"
export const LEAD_EXE_UPDATE_NR_SECOND_FAILED = "LEAD_EXE_UPDATE_NR_SECOND_FAILED"

export const LEAD_COUNT_BY_STATUS = "LEAD_COUNT_BY_STATUS"
export const LEAD_COUNT_BY_STATUS_SUCCESS = "LEAD_COUNT_BY_STATUS_SUCCESS"
export const LEAD_COUNT_BY_STATUS_FAILED = "LEAD_COUNT_BY_STATUS_FAILED"

export const LEAD_PARTNER_COMMUNICATION = "LEAD_PARTNER_COMMUNICATION"
export const LEAD_PARTNER_COMMUNICATION_SUCCESS = "LEAD_PARTNER_COMMUNICATION_SUCCESS"
export const LEAD_PARTNER_COMMUNICATION_FAILED = "LEAD_PARTNER_COMMUNICATION_FAILED"

export const GET_EXPERT_NOTIFICATION_LEAD = "GET_EXPERT_NOTIFICATION_LEAD"
export const GET_EXPERT_NOTIFICATION_LEAD_SUCCESS = "GET_EXPERT_NOTIFICATION_LEAD_SUCCESS"
export const GET_EXPERT_NOTIFICATION_LEAD_FAILED = "GET_EXPERT_NOTIFICATION_LEAD_FAILED"

export const LEAD_AND_USER_EMAIL_CHANGE = "LEAD_AND_USER_EMAIL_CHANGE"
export const LEAD_AND_USER_EMAIL_CHANGE_SUCCESS = "LEAD_AND_USER_EMAIL_CHANGE_SUCCESS"
export const LEAD_AND_USER_EMAIL_CHANGE_FAILED = "LEAD_AND_USER_EMAIL_CHANGE_FAILED"

export const LEAD_AND_USER_EMAIL_CHANGE_HISTORY = "LEAD_AND_USER_EMAIL_CHANGE_HISTORY"
export const LEAD_AND_USER_EMAIL_CHANGE_HISTORY_SUCCESS = "LEAD_AND_USER_EMAIL_CHANGE_HISTORY_SUCCESS"
export const LEAD_AND_USER_EMAIL_CHANGE_HISTORY_FAILED = "LEAD_AND_USER_EMAIL_CHANGE_HISTORY_FAILED"

/*------------------------------Edit Mobile Number------------------------ */
export const LEAD_AND_USER_MOB_CHANGE = "LEAD_AND_USER_MOB_CHANGE"
export const LEAD_AND_USER_MOB_CHANGE_SUCCESS = "LEAD_AND_USER_MOB_CHANGE_SUCCESS"
export const LEAD_AND_USER_MOB_CHANGE_FAILED = "LEAD_AND_USER_MOB_CHANGE_FAILED"

export const LEAD_AND_USER_MOB_CHANGE_HISTORY = "LEAD_AND_USER_MOB_CHANGE_HISTORY"
export const LEAD_AND_USER_MOB_CHANGE_HISTORY_SUCCESS = "LEAD_AND_USER_MOB_CHANGE_HISTORY_SUCCESS"
export const LEAD_AND_USER_MOB_CHANGE_HISTORY_FAILED = "LEAD_AND_USER_MOB_CHANGE_HISTORY_FAILED"

/*------------------------------Edit Mobile Number------------------------ */

export const UPDATE_COMPLAINT_BY_FILTRATION = "UPDATE_COMPLAINT_BY_FILTRATION";

export const GET_CALLBACKLEAD_DATA = "GET_CALLBACKLEAD_DATA"
export const GET_CALLBACKLEADDATA_SUCCESS = "GET_CALLBACKLEADDATA_SUCCESS"
export const GET_CALLBACKLEADDATA_FAILED = "GET_CALLBACKLEADDATA_FAILED"

export const SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER = "SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER"
export const SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_SUCCESS = "SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_SUCCESS"
export const SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_FAILED = "SEND_DOCUMENT_UPLOAD_LINK_TO_CUSTOMER_FAILED"

export const ADD_LEAD_PARTNER_COMMUNICATION = "ADD_LEAD_PARTNER_COMMUNICATION"
export const ADD_LEAD_PARTNER_COMMUNICATION_SUCCESS = "ADD_LEAD_PARTNER_COMMUNICATION_SUCCESS"
export const ADD_LEAD_PARTNER_COMMUNICATION_FAILED = "ADD_LEAD_PARTNER_COMMUNICATION_FAILED"

export const SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER = "SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER"
export const SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_SUCCESS = "SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_SUCCESS"
export const SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_FAILED = "SEND_DOCUMENT_UPLOAD_LINK_TO_PARTNER_FAILED"

export const SEND_ENACH_REGISTRATION_LINK = "SEND_ENACH_REGISTRATION_LINK"
export const SEND_ENACH_REGISTRATION_LINK_SUCCESS = "SEND_ENACH_REGISTRATION_LINK_SUCCESS"
export const SEND_ENACH_REGISTRATION_LINK_FAILED = "SEND_ENACH_REGISTRATION_LINK_FAILED"

export const UPDATE_DOCUMENT_LEAD_FLAG = "UPDATE_DOCUMENT_LEAD_FLAG"
export const UPDATE_DOCUMENT_LEAD_FLAG_SUCCESS = "UPDATE_DOCUMENT_LEAD_FLAG_SUCCESS"
export const UPDATE_DOCUMENT_LEAD_FLAG_FAILED = "UPDATE_DOCUMENT_LEAD_FLAG_FAILED"


export const DOWNLOAD_DOCUMENT_LEAD_FLAG = "DOWNLOAD_DOCUMENT_LEAD_FLAG"
export const DOWNLOAD_DOCUMENT_LEAD_FLAG_SUCCESS = "DOWNLOAD_DOCUMENT_LEAD_FLAG_SUCCESS"
export const DOWNLOAD_DOCUMENT_LEAD_FLAG_FAILED = "DOWNLOAD_DOCUMENT_LEAD_FLAG_FAILED"
